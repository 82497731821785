/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
/* eslint-disable no-alert */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Grid,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Button,
  Paper,
  Backdrop,
  CircularProgress,
} from '@material-ui/core';

const PROXY_IP = '34.93.56.120';
const GATEWAY_IP_PRIVATE = '10.160.15.222';

const getBaseUrl = path => {
  return `https://us-east-1.cloud-prod-http.sportvot.com/${path}?target=${PROXY_IP}:3000`;
};

const isValidIPv4 = ip => {
  if (!ip) return false;
  const parts = ip.split('.');
  if (parts.length !== 4) return false;
  for (let i = 0; i < 4; i += 1) {
    const part = parts[i];
    if (!/^\d+$/.test(part)) return false;
    const num = parseInt(part, 10);
    if (num < 0 || num > 255) return false;
  }
  return true;
};

const FixedCloudProdLinksManager = () => {
  const [tcpRules, setTcpRules] = useState([]);
  const [udpRules, setUdpRules] = useState([]);
  const [newTcpRule, setNewTcpRule] = useState({
    listenPort: '',
    destinationIp: '',
    destinationPort: '',
  });
  const [newUdpRule, setNewUdpRule] = useState({
    listenPort: '',
    destinationIp: '',
    destinationPort: '',
  });
  const [loading, setLoading] = useState(false);
  const [editingRule, setEditingRule] = useState(null);

  const fetchRules = () => {
    axios.get(getBaseUrl('rules')).then(response => {
      const tcp = [];
      const udp = [];
      response.data.data.forEach(rule => {
        if (rule.protocol === 'tcp') tcp.push(rule);
        else udp.push(rule);
      });
      setTcpRules(tcp.sort((a, b) => a.listenPort - b.listenPort));
      setUdpRules(udp.sort((a, b) => a.listenPort - b.listenPort));
    });
  };

  useEffect(() => {
    fetchRules();
  }, []);

  const validateRule = (rule, protocol, isEdit = false) => {
    if (!rule.listenPort || !rule.destinationIp || !rule.destinationPort) {
      alert('All fields are required.');
      return false;
    }

    const listenPort = parseInt(rule.listenPort, 10);
    if (isNaN(listenPort)) {
      alert('Listen port must be a number.');
      return false;
    }

    const portRange =
      protocol === 'tcp'
        ? listenPort >= 20000 && listenPort <= 25000
        : listenPort >= 10000 && listenPort <= 15000;
    if (!portRange) {
      alert('Listen port out of allowed range.');
      return false;
    }

    const destinationPort = parseInt(rule.destinationPort, 10);
    if (
      isNaN(destinationPort) ||
      destinationPort < 1 ||
      destinationPort > 65535
    ) {
      alert('Destination port must be a number between 1 and 65535.');
      return false;
    }

    if (!isValidIPv4(rule.destinationIp)) {
      alert('Destination IP is not a valid IPv4 address.');
      return false;
    }

    const existingRules = protocol === 'tcp' ? tcpRules : udpRules;
    if (
      existingRules.some(
        existingRule =>
          existingRule.listenPort === listenPort &&
          (!isEdit ||
            existingRule.listenPort !== editingRule.originalListenPort),
      )
    ) {
      alert('Listen port is already in use.');
      return false;
    }

    return true;
  };

  const handleAddRule = protocol => {
    const newRule = protocol === 'tcp' ? newTcpRule : newUdpRule;

    if (!validateRule(newRule, protocol)) return;

    setLoading(true);
    axios
      .put(getBaseUrl('rule'), {
        ...newRule,
        protocol,
        gatewayIp: GATEWAY_IP_PRIVATE,
      })
      .then(() => {
        setTimeout(() => {
          fetchRules();
          setLoading(false);
          protocol === 'tcp'
            ? setNewTcpRule({
                listenPort: '',
                destinationIp: '',
                destinationPort: '',
              })
            : setNewUdpRule({
                listenPort: '',
                destinationIp: '',
                destinationPort: '',
              });
        }, 3000);
      });
  };

  const handleEditRule = (rule, protocol) => {
    setEditingRule({
      ...rule,
      protocol,
      originalListenPort: rule.listenPort,
    });
  };

  const handleSaveEdit = () => {
    if (!validateRule(editingRule, editingRule.protocol, true)) return;

    setLoading(true);
    axios
      .put(getBaseUrl('rule'), {
        listenPort: editingRule.listenPort,
        destinationIp: editingRule.destinationIp,
        destinationPort: editingRule.destinationPort,
        protocol: editingRule.protocol,
        gatewayIp: GATEWAY_IP_PRIVATE,
      })
      .then(() => {
        setTimeout(() => {
          fetchRules();
          setLoading(false);
          setEditingRule(null);
        }, 3000);
      });
  };

  const handleCancelEdit = () => {
    setEditingRule(null);
  };

  // eslint-disable-next-line no-unused-vars
  const handleDeleteRule = (listenPort, protocol) => {
    setLoading(true);
    axios.delete(getBaseUrl(`rule/${listenPort}`)).then(() => {
      setTimeout(() => {
        fetchRules();
        setLoading(false);
      }, 3000);
    });
  };

  const handleInputChange = (e, protocol) => {
    const { name, value } = e.target;
    protocol === 'tcp'
      ? setNewTcpRule({ ...newTcpRule, [name]: value })
      : setNewUdpRule({ ...newUdpRule, [name]: value });
  };

  const handleEditInputChange = e => {
    const { name, value } = e.target;
    setEditingRule({ ...editingRule, [name]: value });
  };

  const renderTable = (rules, protocol, newRule) => (
    <Paper style={{ padding: 16 }}>
      <h1>{protocol.toLowerCase() === 'udp' ? 'SRT' : 'RTMP'} Rules</h1>
      {protocol.toLowerCase() === 'udp' && (
        <h2>VALID LISTEN PORTS = 10000 to 15000</h2>
      )}
      {protocol.toLowerCase() === 'tcp' && (
        <h2>VALID LISTEN PORTS = 20000 to 25000</h2>
      )}

      <h3>How to use?</h3>

      {protocol.toLowerCase() === 'udp' && (
        <p>
          [RECOMMENDED] Domain based: set{' '}
          <b>srt://video-input-1.sportvot.com:LISTEN_PORT</b> in your streaming
          software / cam
        </p>
      )}

      {protocol.toLowerCase() === 'udp' && (
        <p>
          IP based: set <b>srt://{PROXY_IP}:LISTEN_PORT</b> in your streaming
          software / cam
        </p>
      )}

      {protocol.toLowerCase() === 'tcp' && (
        <p>
          [RECOMMENDED] Domain based: set{' '}
          <b>rtmp://video-input-1.sportvot.com:LISTEN_PORT/sv/stream1</b> in
          your streaming software / cam
        </p>
      )}

      {protocol.toLowerCase() === 'tcp' && (
        <p>
          IP based: set <b>rtmp://{PROXY_IP}:LISTEN_PORT/sv/stream1</b> in your
          streaming software / cam
        </p>
      )}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Listen Port</TableCell>
            <TableCell>Destination IP</TableCell>
            <TableCell>Destination Port</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rules.map(rule => {
            if (
              editingRule &&
              editingRule.listenPort === rule.listenPort &&
              editingRule.protocol === protocol
            ) {
              return (
                <TableRow key={rule.listenPort}>
                  <TableCell>
                    <TextField
                      name="listenPort"
                      value={editingRule.listenPort}
                      onChange={handleEditInputChange}
                      type="number"
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="destinationIp"
                      value={editingRule.destinationIp}
                      onChange={handleEditInputChange}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="destinationPort"
                      value={editingRule.destinationPort}
                      onChange={handleEditInputChange}
                      type="number"
                    />
                  </TableCell>
                  <TableCell>
                    <Button color="primary" onClick={handleSaveEdit}>
                      Save
                    </Button>
                    <Button color="secondary" onClick={handleCancelEdit}>
                      Cancel
                    </Button>
                  </TableCell>
                </TableRow>
              );
            }

            return (
              <TableRow key={rule.listenPort}>
                <TableCell>{rule.listenPort}</TableCell>
                <TableCell>{rule.destinationIp}</TableCell>
                <TableCell>{rule.destinationPort}</TableCell>
                <TableCell>
                  <Button
                    color="primary"
                    onClick={() => handleEditRule(rule, protocol)}
                  >
                    Edit
                  </Button>
                  <Button
                    color="secondary"
                    onClick={() => handleDeleteRule(rule.listenPort, protocol)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
          <TableRow>
            <TableCell>
              <TextField
                name="listenPort"
                value={newRule.listenPort}
                onChange={e => handleInputChange(e, protocol)}
                type="number"
              />
            </TableCell>
            <TableCell>
              <TextField
                name="destinationIp"
                value={newRule.destinationIp}
                onChange={e => handleInputChange(e, protocol)}
              />
            </TableCell>
            <TableCell>
              <TextField
                name="destinationPort"
                value={newRule.destinationPort}
                onChange={e => handleInputChange(e, protocol)}
                type="number"
              />
            </TableCell>
            <TableCell>
              <Button color="primary" onClick={() => handleAddRule(protocol)}>
                Add
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );

  return (
    <>
      <h3>
        Cloud Production Fixed Links -{' '}
        <a href="https://youtu.be/iOVJVFcziRY" target="_blank">
          Reference Video
        </a>
      </h3>

      <h1>
        IMPORTANT: restart your cam / streaming software if you edit a rule
      </h1>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          {renderTable(tcpRules, 'tcp', newTcpRule)}
        </Grid>
        <Grid item xs={6}>
          {renderTable(udpRules, 'udp', newUdpRule)}
        </Grid>
      </Grid>
      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default FixedCloudProdLinksManager;
``;
