export const CHANNEL_MANAGEMENT = 'channel-management';
export const TV = 'tv';
export const MATCH_MANAGEMENT = 'match-management';
export const ATHLETEMANAGMENT = 'player';
export const TEAMS = 'teams';
export const TOURNAMENT = 'tournament';
export const EVENTS = 'events';
export const STREAMS = 'streams';
export const STREAMS_PIX_HIGHLIGHTS_VAS = 'streams/pix-highlights-vas';
export const ARTICLES = 'articles';
export const PERMISSIONS = 'userpermissions';
export const HEADER_CONTENT = 'header-content';
export const APPROVALS = 'approvals';
export const STREAMING_SERVER = 'streaming-server';
export const USERS = 'users';
export const PASS_CHECK = 'pass-check';
export const MANAGED_HOME_FEED = 'managed-home-feed';
export const JIO_SPORTS_HUB_FEED = 'jio-sports-hub-feed';
export const PIXELLOT_TENANTS = 'pixellot-tenants';
export const CP_FIXED_LINKS = 'cp-fixed-links';
export const SERVER_COSTS = 'server-costs';
